<script>
import { mapState } from 'vuex';

import { BIconTrash,BBadge, BIconSearch } from 'bootstrap-vue';

import formatDate from '@/mixins/formatDate';
import trialHelpers from '@/mixins/trialHelpers';
import TrialBanner from '../components/TrialBanner.vue';
import ClioImport from '../clioImport.vue';
import { performInitialClioLoginOperation,setLoginProviderAndClioFetchValuesToStorage } from '../clioIntegration';
import EmailPasswordLogin from './EmailPasswordLogin.vue';
export default {
	name: 'clioHome',
	components: {
		BIconTrash,
		TrialBanner,
		BBadge,
		ClioImport,
		BIconSearch,
		EmailPasswordLogin
	},
	data() {
		return {
			showGoogleLoginConfirmation : false,
			clioLoading:true
		};
	},
	methods: {
		loader(value){
			console.log("isLoading: ",value)
			this.clioLoading = value;
		},
		redirectAndShowGoogleLoginPopup(){
			console.log('---redirectAndShowGoogleLoginPopup---')
			this.$router.push({ path: '/auth' , query: {auth:0, page:2, clioFetch:true, authProvider:'GOOGLE'}});
		},
		redirectToLanding(){
			this.$store.dispatch('logout');
			this.$router.push({ path: '/auth'})
		}
	},
	computed: {
		...mapState(['user','agreements', 'userProfile','subscriptions']),
		loadingAgreements() {
			return this.$store.getters.loading;
		},
	},
	mounted() {
		console.log(' clio home mounted');
		if(localStorage.getItem('showGoogleLoginConfirmation') && localStorage.getItem('showGoogleLoginConfirmation')=='true'){
			this.showGoogleLoginConfirmation = true;
		}
		if(this.$route.query && this.$route.query.code){
			sessionStorage.setItem('access_code',this.$route.query.code)
			setLoginProviderAndClioFetchValuesToStorage();
			this.loader(true)
			performInitialClioLoginOperation(this.$route.query.code).then((res)=>{
				console.log('clioHome performInitialClioLoginOperation => ', res);
				let routeParamsObj;
				if(localStorage.getItem('redirectRouteParams'))
					routeParamsObj = JSON.parse(localStorage.getItem('redirectRouteParams'))
				if(res && res.state){
					this.loader(false);
					if(res.state == 'SHOW_LOGIN'){
						let loginProvider = localStorage.getItem('loginProvider');
						if((routeParamsObj.auth == "0" || routeParamsObj.auth == 0) && loginProvider != 'CLIO'){
							console.log('home')
							this.$router.push({ name: 'home' });
						} else {
							if(routeParamsObj){
								routeParamsObj.auth=0;
								routeParamsObj.page=3;
							}
							console.log('auth---')
							this.$router.push({ path: '/auth' , query: routeParamsObj});
						}
					}
					if(res.state == 'SHOW_SIGN_UP'){
						if(routeParamsObj){
							routeParamsObj.auth=1;
							routeParamsObj.page=3;
						}
						this.$router.push({ path: '/auth' , query: routeParamsObj});
					}

					if(res.state == 'SHOW_GOOGLE_POPUP'){
						this.showGoogleLoginConfirmation = true;
						localStorage.setItem('showGoogleLoginConfirmation',this.showGoogleLoginConfirmation)
					}
				}
			}).catch((err)=>{
				console.log('err =>', err)
			});
			// this.$router.replace('/')
			// this.loader(false)
		} else if(this.$route.query && this.$route.query.error == 'access_denied'){
			this.$router.push({ path: '/login' });
		}
	},
	mixins: [formatDate, trialHelpers],

};
</script>

<style>

.home-wrapper {
	z-index: 2;
	min-height: calc(100vh - 10rem);
}

::-webkit-search-cancel-button{
	cursor: pointer;
}

.spinner-outer{
	display: table;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
	top: 50%;
	left: 50%;
}
.card-outer-clio{
	max-width: 20rem;
	margin: 5rem auto auto auto;
}
</style>

<template>
	<b-container class="mt-5">
		<b-card class="home-wrapper">
			<b-spinner
				style="width: 3rem; height: 3rem"
				variant="primary"
				label="Loading..."
				class="spinner-outer"
				v-if="clioLoading"
			/>
			
			<template v-if="showGoogleLoginConfirmation">
				<b-card bg-variant="light" class="text-center card-outer-clio">
					<b-card-text>The clio account needs google signin to continue.
						 Do you want to sign in using google to access DIVII ?</b-card-text>
					<b-button size="sm" variant="navy" class="mb-4" @click="redirectAndShowGoogleLoginPopup()" data-testid="force-sign-in-google"> Yes. Sign in with Google &nbsp; <img
                                                        src="@/assets/googleLogo.svg" alt="" /></b-button>
					<b-button size="sm" variant="link" @click="redirectToLanding()" data-testid="back-to-landing"> No. I will choose my login preference </b-button>
				</b-card>
			</template>
		</b-card>
	</b-container>
</template>
