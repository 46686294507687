<template>
	<b-card>
		<b-form>
			<b-form-group label="Email" label-for="input-email" class="mb-1">
				<b-form-input
					id="email"
					v-model="email"
					name="email"
					required
					placeholder="Email"
					data-testid="user-email"
					class="cursorText"
				/>
			</b-form-group>
			<b-form-group
				label="Password"
				label-for="input-password"
				class="mb-3"
			>
				<b-form-input
					id="password"
					v-model="password"
					name="password"
					type="password"
					placeholder="Password"
					required
					data-testid="user-password"
					class="cursorText"
				/>
			</b-form-group>
			<b-form-checkbox size="sm" v-if="!routedFromClioLogin" v-model="connectWithClio">Retrieve CLIO matters. This will need  additional login to CLIO</b-form-checkbox>
			<b-button
				variant="primary"
				block
				:disabled="!canLogin || loading"
				@click="login"
				data-testid="email-login-button"
				class="cursor"
			>
				<b-spinner v-if="loading" small></b-spinner>
				<span v-else>Login</span>
			</b-button>
			<div v-if="error">{{ error }}</div>
			<div v-if="showEmailSent">Password Reset Email Sent.</div>
			<!-- <b-link
				v-if="
					(failedAttemps >= 3 || showPasswordReset) &&
					!showCreateAccount
				"
				:disabled="!email"
				@click="resetPassword"
				>Reset Password</b-link
			> -->
			<b-link v-if="showCreateAccount" @click="needsAccount" class="cursor" data-testid="dont-have-an-account">
				Don't have an account, click here?
			</b-link>
		</b-form>
	</b-card>
</template>

<script>
import isEmail from 'validator/lib/isEmail';
import { auth,customersCollection } from '../firebase';
// import * as fb from '../firebase';
import clientUserFormHelper from '@/mixins/clientUserFormHelper';

// import { auth } from '../firebase';
import { clioLogin } from '../clioIntegration';

function email(value) {
	return value && isEmail(value.trim());
}

export default {
	props: ['prefilledEmail','routedFromClioLogin'],
	data() {
		return {
			email: '',
			password: '',
			error: '',
			loading: false,
			failedAttemps: 0,
			showPasswordReset: false,
			showCreateAccount: false,
			showEmailSent: false,
			connectWithClio:false
		};
	},
	computed: {
		canLogin() {
			return email(this.email) && this.password != '';
		},
	},
	mixins: [clientUserFormHelper],
	mounted(){
		if(this.prefilledEmail && this.routedFromClioLogin){
			this.email = this.prefilledEmail;
			this.connectWithClio = true;
		}
	},
	methods: {
		async login() {
			this.loading = true;
			try {
				const userSnap = await auth.signInWithEmailAndPassword(
					this.email,
					this.password
				);
				this.$store.commit('setLoginProvider', 'EMAIL')
				// setLoggedIn(true);
				this.$store.dispatch('login', userSnap.user).then(async() => {
					
					console.log(`User ${userSnap.user.email} logged in`);
						let loggedInCustomer = await this.getCustomerUsingEmail(userSnap.user.uid,userSnap.user.email);
						console.log(`User abc--> ${JSON.stringify(loggedInCustomer)}`);		
						this.loading = false;
						// if(this.getClientOrPreviouslyLoggedId && loggedInCustomer && loggedInCustomer.isClientUser){
						// 	if(this.getPreviouslyLoggedClientUserAgreement)
						// 		localStorage.setItem('clientUserAgreement', this.getPreviouslyLoggedClientUserAgreement);
						// 	this.$router.push({
						// 		name: 'clientUserAgreement',
						// 		params: { id: this.getClientOrPreviouslyLoggedId },
						// 	});
						// } else
						// this.$router.push({ name: 'home' });
						this.$router.push({ name: 'postLogin' });
					
					// if(this.connectWithClio){
					// 	this.$store.commit('setLoginProvider', 'CLIO')
					// 	if(!this.routedFromClioLogin){
					// 		clioLogin()
					// 	} else {
					// 		this.$router.push({ name: 'home' });
					// 	}
					// } else
					// this.$router.push({ name: 'home' });
				});
			} catch (error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				console.error(errorCode, errorMessage);

				this.loading = false;
				if (errorCode == 'auth/user-not-found') {
					// TODO redirect to the create account thing?
					this.error = 'User does not exist';
					this.showCreateAccount = true;
				} else if (
					errorCode == 'auth/wrong-password' ||
					errorCode == 'auth/invalid-email'
				) {
					this.error = 'Invalid username or password';
					this.failedAttemps += 1;
				} else if (errorCode == 'auth/user-disabled') {
					this.error = 'Account Disabled';
					this.showPasswordReset = true;
				}
			}
			//  finally {
			// 	console.log('finally');
			// 	this.loading = false;
			// }
		},
		async resetPassword() {
			try {
				await auth.sendPasswordResetEmail(this.email);
			} catch (error) {
				var errorCode = error.code;
				if (errorCode == 'auth/user-not-found') {
					this.showPasswordReset = false;
					this.error = 'Something went wrong';
				}
			}
			this.showPasswordReset = false;
			this.showEmailSent = true;
		},
		needsAccount() {
			this.$router.push({ name: 'trial' });
		},
		async getCustomerUsingEmail(uid,email){
			console.log('getCustomerUsingEmail email-->',email);
			const cust  = await customersCollection.doc(uid).get();
			console.log('cust-->',cust.data())
			return cust && cust.data() ? cust.data() : {};
		}
	},
};
</script>
<style>

.cursor{
	cursor: pointer;
}

.cursorText{
	cursor: text;
}

</style>
